.schedule-table-cell{
  font-size: 1rem;
}
.round, .holes {
  text-align: center;
}
@media screen and (max-width: 600px) {
  .round, .holes {
    display: none;
  }
}